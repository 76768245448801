<template>
  <div class="container">
    <div class="card overflow-y overlapping-container has-top-shadow">
      <div class="card-content pb-6 px-4 pt-1">
        <CardNavigation/>
        <ViewsStat :pageDetails="{pageName: 'invite'}"/>
        <div class="container has-text-centered mb-3">
          <div class="py-4 is-size-5 has-text-weight-semibold has-baskerville-font has-text-brand-color">
            Invite Team
          </div>
        </div>
        <div class="container has-text-centered">
          <div class="is-size-6 has-text-grey mb-0 px-4">
            Invite link:
          </div>
          <div @click="copyGameLink()" class="is-size-6 has-text-weight-bold has-text-grey is-clickable mb-4 px-4">
            {{ shareLink }}
          </div>
          <div class="is-size-6 has-text-grey mb-3 px-4">
            Anyone can join at any point of time.
          </div>
          <a
            @click="copyGameLink()"
            class="button overflow-button has-text-weight-semibold overflow-button is-medium is-primary mb-3"
            >
              <i class="fas fa-clipboard mr-2"></i>
              {{ urlCopied ? 'Link copied, send it to your team members!' : 'Copy link to clipboard' }}
          </a>
          <div class="is-size-6 has-text-grey mb-3 px-4">
            or show this QR code
          </div>
          <qrcode-vue :value="shareLink" :size="150" level="H" />
        </div>
        <hr class="solid my-5">
        <div class="container mb-6 pb-4">
          <div class="is-size-5 mb-2 has-text-weight-semibold">
            Players joined ({{ adventureTeam.teamMembers.length }}):
          </div>
          <div v-for="(member, index) in adventureTeam.teamMembers" :key="index" class="is-size-6">
              <i v-if="member.name !== userName" class="fas fa-user fa-fw mr-2"></i>
              <i v-if="member.name === userName" class="fas fa-user-check fa-fw mr-2"></i>
              {{ member.name }}
          </div>
        </div>
        <div class="container has-text-centered mb-5 pb-5">
          <div @click="$router.push({ name: 'Stage' })" class="button is-small is-primary is-rounded">
            <i class="fas fa-clipboard mr-2"></i>
            Back to {{ $store.state.uiMods.task.toLowerCase() }}s page
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardNavigation from '@/components/CardNavigation'
import ViewsStat from '@/components/ViewsStat.vue'
import QrcodeVue from 'qrcode.vue'

export default {
  name: 'InviteTeam',
  components: {
    ViewsStat,
    CardNavigation,
    QrcodeVue
  },
  data() {
    return {
      urlCopied: false
    }
  },
  methods: {
    async copyGameLink(){
      this.urlCopied = true
      await navigator.clipboard.writeText(this.shareLink);
      setTimeout(() => { this.urlCopied= false }, 1500)
    }
  },
  computed: {
    teamCode(){
      return this.$store.state.teamCode
    },
    adventureTeam(){
      return this.$store.state.adventureTeam
    },
    userName(){
      return this.$store.state.userName
    },
    shareLink(){
      if(this.adventureTeam){
        return `${window.location.origin}/lobby/${this.teamCode}`
      }
      return ''
    }
  },
  mounted() {
    this.$store.dispatch('enterPage', {
      pageName: 'invite'
    })
  }
}
</script>
